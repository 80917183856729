exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mezcales-js": () => import("./../../../src/pages/mezcales.js" /* webpackChunkName: "component---src-pages-mezcales-js" */),
  "component---src-pages-mixologia-js": () => import("./../../../src/pages/mixologia.js" /* webpackChunkName: "component---src-pages-mixologia-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-templates-coctel-js": () => import("./../../../src/templates/Coctel.js" /* webpackChunkName: "component---src-templates-coctel-js" */),
  "component---src-templates-mezcal-js": () => import("./../../../src/templates/Mezcal.js" /* webpackChunkName: "component---src-templates-mezcal-js" */)
}

